import styled from 'styled-components';
import { StyledLink } from 'components/ui/link';
import { media } from 'theme/breakpoints';

export const Banner = styled.div`
  background-color: #f8f8f8;
  border-radius: 4px;
  padding: 48px;
  display: grid;
  grid-template-columns: 1fr minmax(300px, 339px);
  grid-column-gap: 50px;
  align-items: center;
  margin-bottom: 72px;
  z-index: 1;
  position: relative;
  color: #000;
  ${media.desktopWide} {
    grid-template-columns: 1fr;
    grid-gap: 52px;
  }
  ${media.tablet} {
    padding: 32px;
  }
  ${media.mobile} {
    width: 100vw;
    transform: translateX(-15px);
  }
`;

export const MainContent = styled.div`
  & svg {
    width: 32px;
    height: 32px;
    margin-bottom: 16px;
    & .yellow {
      fill: #ffe478;
    }
    & .blue {
      fill: #7dffef;
    }
    & .purple {
      fill: #8765f9;
    }
    ${media.desktopWide} {
      margin-bottom: 8px;
    }
  }
  & h3 {
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 24px;
    ${media.desktopWide} {
      font-size: 36px;
      line-height: 48px;
    }
  }
  & p {
    font-size: 16px;
    line-height: 28px;
    margin: 0;
  }
`;

export const Card = styled.a`
  border-radius: 4px;
  background-color: #ffffff;
  border: 2px solid #000000;
  padding: 24px;
  position: relative;
  z-index: 20;
  color: #000;
  transition: 0.2s ease;
  display: block;
  &:hover {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    text-decoration: none;
    color: #000;
    & ${StyledLink} {
      text-decoration: underline;
      &::after {
        transform: translateX(8px);
      }
    }
  }
  &:focus {
    color: #000;
    outline: 0;
  }
  & .header-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    & img.logo {
      width: 24px;
      height: 24px;
    }
    & div {
      display: flex;
      align-items: center;
      & img {
        margin-right: 9px;
        width: 12px;
        height: 12px;
      }
      & p {
        font-weight: 800;
        font-size: 14px;
        line-height: 24px;
        margin: 0;
      }
    }
  }
  & h4 {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }
  & p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  & ${StyledLink} {
    font-size: 14px;
    margin: 0;
  }
`;
