import React from 'react';
import Layout from 'layout';
import { GridBox } from 'pages/integrations';
import SideBar from 'components/marketplace/sideBar';
import Content from 'components/marketplace/content';
import styled from 'styled-components';
import Banner from 'components/marketplace/banner';
import { frontmatter } from 'data/marketplace/integrations';
import arrow from 'img/ui/arrow_black.svg';
import OwnAppSection from 'components/marketplace/ownAppSection';
import { media } from 'theme/breakpoints';

export const ExtendGridBox = styled(GridBox)`
  padding-bottom: 120px;
  margin-top: 144px;
  grid-template-rows: auto auto;
  grid-row-gap: 56px;
  grid-template-areas:
    'breadcrumbs breadcrumbs'
    'sidebar content';
  ${media.tablet} {
    margin-top: 110px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'breadcrumbs'
      'sidebar'
      'content';
    grid-gap: 40px;
  }
  ${media.mobile} {
    grid-template-columns: calc(100vw - 30px);
  }
  .breadcrumbs {
    grid-area: breadcrumbs;
    display: flex;
    align-items: center;
    & img {
      margin: 0 16px;
    }
    & p {
      margin: 0;
      line-height: normal;
      & a {
        color: #000;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
`;

const Analytics = () => {
  const [mostPopularCard] = frontmatter.items.filter((item) => item.title === 'Google Analytics');
  return (
    <Layout
      metaTitle="Analytics | Integrations"
      canonical="/integrations/analytics/"
      metaDescription="Connect qualitative and quantitative analytics for even more context."
    >
      <div className="container">
        <ExtendGridBox>
          <div className="breadcrumbs">
            <p>
              <a href="/integrations/">Integrations</a>
            </p>
            <img src={arrow} alt="arrow" title="Arrow" />
            <p>
              <a href="/integrations/analytics/">
                <strong>Analytics</strong>
              </a>
            </p>
          </div>
          <SideBar activeCategory="Analytics" underlineColor="#FFE478" />
          <Content activeCategory="Analytics" hideTitle>
            <Banner
              activeCategory="Analytics"
              card={mostPopularCard}
              text="Connect qualitative and quantitative analytics for even more context."
              underlineColor="#FFE478"
            />
          </Content>
        </ExtendGridBox>
        <OwnAppSection />
      </div>
    </Layout>
  );
};

export default Analytics;
